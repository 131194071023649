.app-section-download-text {
    font-size: 18px;
    vertical-align: middle;
}

.mockup-video {
    line-height: 32px;
}

.app-section-cart-logo {
    margin-right: 12px;
}

.mockup-section-download {
    text-align: left;
    margin-top: 24px;
}

.mockup-section-download-text{
    font-weight:700;
    font-size:18px;
}

.mockup-section-sub-heading{
    margin-top:24px;
    font-size:var(--heading-h2);
    font-weight:500
}
.mockup-section-sub-text{
    margin-top:24px;
    font-size:var(--para-p1);
    padding: 0 15%;
    
}
.mockup-section-text {
    color:var(--color-secondary);
    font-size: var(--heading-h4);
    font-weight: 400;
    text-align:left;
    line-height:25px;
    margin-left:16px;
}

.mockup-section-list-item{
    display:flex;
    align-items:flex-start;
}
.mockup-section-heading{
    line-height:44px;
}

.mockup-section-ul{
    width:80%
}

.work-card-title{
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
}

.work-card-para{
    font-size: 16px;
    font-weight:400;
}

.work-card{
    text-align: left;
}

.working-content-mob-img{
    text-align: left;
}
@media screen and (max-width:576px) {
    .app-section-image-video {
        flex-direction: column-reverse;
        padding-top: 30px !important;
    }

    .mockup-section-video {
        padding-right: 12px;
    }

    .mockup-section-sub-heading{
        font-size:var(--heading-h4);
        margin-top:22px;
    }
    .mockup-section-sub-text{
        font-size:var(--para-p1);
        margin-top:22px;
        line-height:22px;
        padding: 0;
    }

    .mockup-section-ul{
        margin-top:22px;
    }
    .mockup-section-text{
        font-size: var(--heading-h4);
    }
    .mockup-section-ul img{
        width:23px;
    }

    .card-body{
        padding:24px 0px;
    }
}

@media (min-width: 577px) and (max-width: 1200px) {
    .mockup-section-text{
        font-size:var(--heading-h4);
    }
}

@media screen and (max-width:768px) {
    .work-card{
        text-align: center;
    }
    
    .working-content-mob-img{
        text-align: center;
    }
}