.tech-cards{
    background:var(--color-tertiary);
    color:var(--color-primary);
    text-align: left;
    padding-left:0
 
}
.tech-wrapper{
    padding: var(--padding-p2) var(--padding-p1) 2rem var(--padding-p1);
}
.tech-card-heading{
    font-size: 32px;
    font-weight: 700;

}

.tech-card-text{
    font-size: 20px;
    font-weight: 400;
    color:var(--color-primary);
}
.tech-content{
    margin-bottom: 40px;
}
.learning-content{
    margin-top: 60px;
}
.learn-heading{
    font-size: 48px;
    font-weight: 700;
    color:#363638
}
.learn-content img{
    margin-top: 3.5rem
}

.learn-content{
        margin-bottom:10rem;
}
.tech-cards-content{
    padding-left:0
}
.learning-text{
    padding-left:19rem;
    padding-right:19rem;
}
.tech-card-fullheight{
    height:100%;
}

.tech-card-fullheight img{
    height:98%;
}

@media (min-width: 577px) and (max-width: 1200px) {

    .tech-wrapper{
        padding: var(--padding-p4) var(--padding-p5) var(--padding-p6) var(--padding-p5);
    }

    .tech-adv-section-heading{
        font-size:var(--heading-h2);
        margin-bottom:3rem;
    }
  

}

@media screen and (max-width:576px) {
        .tech-wrapper{
            padding:2rem 0rem 0rem 0rem;
        }
}