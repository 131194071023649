.partners-heading{
    text-align: center;
    font-size: var(--heading-h1);
    font-weight: 700;
    line-height: 61px;;
    line-height: 32px;
    margin-bottom:0
    
}

.partners{
    background: var(--color-secondary);
    padding: var(--padding-p2) var(--padding-p1);
    color: var(--color-tertiary);
}
.partners-content-text{
    margin: 20px 0;
    font-size:var(--para-p1);
    padding:0 14rem;
    line-height:28px
}

.partner-join-btn{
    padding:10px 42px;
    background:var(--color-tertiary);
    color:var(--color-secondary);
    font-size: 18px;
    font-weight:600;
    border:none;
}

.partner-join-btn:hover{
    background:var(--color-tertiary);
    color:var(--color-secondary);
}
@media screen and (max-width:576px){
    .partners{
        padding:2rem var(--padding-p6) 2rem var(--padding-p6);
    }

    .partners-heading{
        font-size: var(--heading-h3);
        font-weight:700;
    }
    .partners-content-text{
        padding:0
    }
}

@media (min-width: 577px) and (max-width: 1200px) {

    .partners{
        padding:var(--padding-p4) 5rem;
    }

    .partners-content-text{
        padding:0
    }

    .partners-heading{
         font-size:var(--heading-h2);
    }
}