.app-section {
    padding: var(--padding-p2) var(--padding-p1);
    background-color: var(--color-secondary);
    text-align: center;
    line-height: 44px;
}

.app-section-sub-heading {
    font-size: var(--heading-h2);
    font-weight: 500;
    color: var(--color-tertiary);
    margin-bottom: 20px;
    line-height:36px;
}

.app-section-text {
    color: var(--color-tertiary);
    font-size: 20px;
    font-weight: 400;
    line-height:28px;
    margin-bottom:0;
    padding:0 16%;
}

.app-section-heading {
    text-align: center;
    font-size: var(--heading-h1);
    font-weight: 700;
    color: var(--color-tertiary);
    margin-bottom: 24px;
    padding:0 16%;
}

.app-section-image-video {
    justify-content: space-evenly;
    padding-top: 56px;
}

.app-section-download {
    text-align: left;
    margin-top: 32px;
    display: flex;

}

.app-section-download-image img{
    width:80%;
}

.mobile-view {
    display: none !important;
}

.play-store-btn img {
    width: 100%
}

.mobile-app-image img {
    height: 86%;
}

.mobile-app-image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.app-section-video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
}


.app-section-app-images{
    display:flex;
    justify-content: space-around;
    margin: 4% auto 0 auto;
    width:90%;
}
@media screen and (max-width:576px) {
    .app-section {
        padding: 2rem var(--padding-p6);

    }

    .app-section-download {
        margin-top: 0px;
    }

    .app-section-heading {
        font-size: var(--heading-h3);
        margin-bottom:22px;
        line-height:30px;
        padding:0;
    }

    .app-section-sub-heading {
        font-size: var(--heading-h4);
        margin-bottom:22px;
        line-height:30px;
    }

    .desktop-view {
        display: none !important;
    }

    .mobile-view {
        display: block !important;
        margin-bottom: 18px;
        text-align: left;
        font-size: 16px;

    }

    .app-section-text {
        margin-top: 22px;
        padding:0
    }

    .app-section-video {
        width: 100%;
        padding-left: 12px;
    }
}

@media screen and (max-width:440px) {
    .play-store-btn {
        margin-bottom: 24px;
    }

}

@media (min-width: 577px) and (max-width: 1200px) {
    .app-section {
        padding: var(--padding-p4) var(--padding-p5);
    }

    .app-section-heading{
        font-size:var(--heading-h2);
        margin-bottom:22px;
        padding:0 16%;

    }

    .app-section-sub-heading{
        font-size:var(--heading-h4);
        margin-bottom:18px;
        padding:0 16%;
    }
    .app-section-image-video{
        padding-top:48px;
    }
    .app-section-download{
        margin-top:24px;
    }
}