.section-intro{
    margin-bottom: 4rem;
}

.team-video-section{
    margin-top:1.5rem;
}

.story-content-title {
    font-size: var(--heading-h1);
    font-weight: 700;
    margin-bottom: 24px;
    color:var(--color-tertiary);
}

.story-text {
    font-size: 16px;
    font-weight: 400;
    padding:0 15%;
    line-height:28px;
    margin-bottom:0;
    color:var(--color-tertiary);
}

.section-intro-subheading{
    font-size: var(--heading-h2);
    font-weight: 700;
    color:var(--color-tertiary);
    margin-bottom:20px;
}
@media screen and (max-width:576px) {
    .story-text{
        padding:0
    }
    .story-content-title {
        font-size: var(--heading-h3);
        margin-bottom:22px;
    }
    .section-intro{
        
    }
}

@media (min-width: 577px) and (max-width: 1200px) {
.story-content-title{
    font-size:var(--heading-h2);
    margin-bottom:18px;
}

}