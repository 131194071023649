.heading {
    font-weight: 600;
    font-size: 56px;
    text-align: left;
    margin-bottom: 32px;
    line-height: 54px
}

.content {
    padding: var(--padding-p1) var(--padding-p1) 0 var(--padding-p1)
}

.intro {
    text-align: left;
    padding-bottom: 4rem;
}

.text {
    text-align: left;
    margin-bottom: 16px;
    padding-right:3.5rem;
    font-size:16px;
}

.intro-btn {
    background: var(--color-secondary);
    color: var(--color-tertiary);
    font-size: 18px;
    font-weight: 400;
    padding: 10px;
    border-radius: 4px;
    border: none;
}

.intro-btn:hover{
    background-color: var(--color-secondary);
}

.telephone-icon {
    margin-right: 8px;
    align-content: center;
}

.container {
    padding: 0;
    margin: 0 !important
}

.working {
    background-color: var(--color-secondary);
    padding: var(--padding-p2) 9rem;
}

.working-heading {
    text-align: center;
    font-size: var(--heading-h1);
    font-weight: 700;
    color: var(--color-tertiary);
    line-height:32px;
   
}
.mockup-section {
    padding: var(--padding-p2) var(--padding-p1);
    background-color: #E3E7EA;
    text-align: center;
    line-height:32px;
    color:var(--color-secondary);
}

.mockup-section-heading {
    text-align: center;
    font-size: var(--heading-h1);
    font-weight: 700;
    color:var(--color-secondary);
}



.mockup-video {
    background-color: var(--color-secondary);
    padding: 10px;
    border: none;
    color: var(--color-tertiary);
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    ;
}

.tech-adv-section {
    padding: 173px 12rem 80px 12rem;
    text-align: center;
    color: var(--color-primary);

}

.tech-adv-section-heading {
    text-align: center;
    font-size: var(--heading-h1);
    font-weight: 700;
    color: var(--color-primary);
    margin-bottom: 4rem;
}

.tech-adv-section-text {
    padding-right: 20px;
    background: var(--color-tertiary);
    color: var(--color-primary);

}

.play-store-btn {
    padding: 0;
    margin-right: 1.5rem;
    background: var(--color-tertiary);
    border-radius: 8px;
    border: 1px solid #a6a6a6
}

.tech-adv-text {
    text-align: left;
    padding-left: 0
}

.mobile-view {
    display: none !important;
}

.tech-card-detail {
    margin-top: 3rem;
}

@media screen and (max-width: 576px) {
    .heading {
        font-size: 36px;
        margin-top:32px;
        line-height:42px;
        margin-bottom:16px;
    }

    .content {
        padding: 1.5rem var(--padding-p6) 0 var(--padding-p6);
    }

    .text {
        font-size: 16px;
        
    }

    .working {
        padding: 2rem var(--padding-p6) 2rem var(--padding-p6);
    }

    .working-heading {
        font-size: var(--heading-h3);
    }

    .card-detail {
        margin-top: 30px;
    }

    .intro {
        padding-bottom: 32px;
    }

    .working-card {
        margin-bottom: 24px;
    }

    .working-card:nth-child(3) {
        margin-bottom: 0;
    }

    .mockup-section {
        padding: 2rem var(--padding-p6) 2rem var(--padding-p6);
    }

    .mockup-section-heading {
        font-size: var(--heading-h3);
        margin-bottom: 16px;
    }


    .mockup-section-detail {
        margin-bottom: 0
    }

    .tech-adv-section {
        padding: 3rem 1rem 3rem 1rem;
    }

    .tech-adv-section-heading {
        font-size: var(--heading-h3);
        margin-bottom: 12px;
        line-height: 40px;
    }

    .desktop-view {
        display: none !important;
    }

    .mobile-view {
        display: block !important;
    }

    .tech-adv-section-text {
        padding: 0;
        margin-top: 1rem;
        text-align: center;
    }

    .mobile-view-btn {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .card-heading{
        font-size:var(--heading-h4);
        margin-bottom:8px;
    }
    .card-text{
        font-size:var(--para-p1);
        line-height:21px;
    }

    .intro-btn{
        font-size:16px;
    }

}

@media (min-width: 577px) and (max-width: 1200px) {
    .content {
        padding: var(--padding-p1) var(--padding-p5) var(--padding-p4) var(--padding-p5);

    }

    .heading {
        font-size: 42px;
        margin-bottom:26px;
    }
    .working {
        padding: var(--padding-p4) var(--padding-p5);
    }

    .mockup-section {
        padding: var(--padding-p4) var(--padding-p5);
        line-height:32px;
    }

    .mockup-section-text {
        text-align: left;
    }

    .tech-adv-section {
        padding: 5rem 2.5rem;
    }
    .intro{
        padding-bottom:0;
    }

    .card-detail{
            margin-top:28px;
    }
}