.tech-card-title{
    font-size: 26px;
    font-weight:700;
    text-align: left;
    line-height:22px;
    margin-bottom:0
}

.tech-card-text{
    font-size: var(--para-p1);
    font-weight: 400;
    text-align: left;
    color:var(--color-tertiary);
    line-height:28px;
}

.tech-mas-card-content{
    height:100%;
    position:relative;
    cursor: pointer;
    border-radius:4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
}

.tech-card-details{
    position:absolute;
    bottom:0;
    padding:20px;
    color:var(--color-tertiary);
    z-index:1;
    left:0;
    background: linear-gradient(-360deg, #000000 -77%, rgba(0, 0, 0, 0) 87.69%);
}

.tech-card-text{
    margin-bottom: 0;
    margin-top:20px;
}

.tech-mas-card-content img{
    width: 100%;
    height: 100%;
    -webkit-transition: 0.2s all;
}

.tech-mas-card-content img:hover {
    -webkit-filter: brightness(50%);
    transform: scale(1.1);
    
  }

  
@media screen and (max-width:576px){
    .tech-card-text{
        font-size: 14px;
        margin-top:6px;
        line-height:20px;

    }
    .tech-card-title{
        font-size: 16px;
    }
    .tech-card-details{
        padding:10px 12px;
    }
}

@media (min-width: 577px) and (max-width: 1200px) {
    .tech-card-text{
        line-height:20px;
        margin-top:10px;

    }

    .tech-card-title{
        font-size: 22px;
    }

}