.navbar-desktop {
    display: flex;
    justify-content: space-between;

}

.cliste-logo {
    margin-right: 16px;
    padding: 4px 0 4px 10px;
}

.wrapper {
    padding-left: 12rem;
    padding-right: 12rem;
    padding-top: 2.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%
}

.page-nav {
    background-color: rgba(242, 242, 242, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 10px 14px;
}

.icons-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.icon {
    background-color: rgba(242, 242, 242, 0.5);
    color: var(--color-primary);
}

.icons-nav img {
    height: 22.72px;
    width: 24.26px;
}

.nav-buttons {
    background-color: rgba(242, 242, 242, 0);
    color: var(--color-primary);
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    transition: background-color 1s ease-in-out ;
    border-radius: 27px;

}

.nav-buttons:hover {
    background-color: #fff;
    color: var(--color-primary);
    border-radius: 27px;
    padding: 4px, 10px;

}

.nav-buttons-icon {
    background-color: rgba(242, 242, 242, 0);
    color: var(--color-primary);
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.nav-buttons-icon:hover {
    color: var(--color-primary);
}

.nav-buttons:active {
    background-color: #fff;
    color: var(--color-primary);
    border-radius: 27px;
    padding: 4px, 10px, 4px, 10px;

}

.nav-buttons-icon p {
    margin-bottom: 0px;
    margin-right: 10px;
}

.glassmorphism {
    backdrop-filter: blur(10px);
    background-color: rgba(242, 242, 242, 0.5);
}

.selected {
    background-color: #fff;
    border-radius: 27px;
}

.btn:active {
    background-color: #fff;
}

.links {
    border-radius: 8px;
    padding: 10px 0;
}

.nav-buttons-app-icon {
    background-color: rgba(242, 242, 242, 0);
    color: var(--color-primary);
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.nav-buttons-app-icon:hover {
    background-color: rgba(242, 242, 242, 0);
    color: var(--color-primary);
}

.wrapper-mobile {
    padding: 2.5rem;
}

.nav-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-toggler-icon {
    background-image: url("../../assets/images/toggle-menu.svg");
}

.navbar-toggler {
    padding: 0;
}

.navbar-toggler:focus,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.navbar-toggler {
    border: 0px;
}

.navbar {
    display: inline-block
}

.navbar-collapse {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1rem;
    text-align: left;
}

.toggle-nav-links {
    font-size: 24px;
    font-weight: 600
}

.header-desktop {
    display: block !important;
}

.header-mobile {
    display: none !important;
}


@media screen and (min-width: 577px) and (max-width: 1200px) {
    .wrapper {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

}

@media screen and (max-width:576px) {
    .wrapper-mobile {
        padding: 1.75rem 1rem 0 1rem;
    }

    .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .header-desktop {
        display: none !important;
    }

    .header-mobile {
        display: block !important;
    }
}