body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
  color:#363638;
  
}

.font-headings{
  font-family: 'Raleway';
}

.font-paragraphs{
  font-family: 'Nunito Sans';
}


:root{
  /* colors */
  --color-primary:#363638;
  --color-secondary:#315C66;
  --color-tertiary: #FAFAFA;
  --color-accent:#050301;
  --color-secondary-2:#048B9E;
  --color-contrastwhite:  #E3E7EA;

  /* typography */
  --heading-h1:42px;
  --heading-h2:32px;
  --heading-h3:24px;
  --heading-h4:18px;
  --para-p1:16px;

  /* padding */
  --padding-p1:12rem;
  --padding-p2:4rem;
  --padding-p4:3rem;
  --padding-p5:2.5rem;
  --padding-p6:1rem;
}
