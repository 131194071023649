.footer {
    background: var(--color-primary);
    color: var(--color-tertiary);
    padding: var(--padding-p2) var(--padding-p1);
}

.footer-heading {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 16px;
}

.footer-form {
    display: flex;
    width: 25rem;
}

.get-updates-field-input-footer {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.footer-submit-btn {
    background-color: #FF8164;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    border: none;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 48px;
    line-height: 20px;
    width: 178px;
    
}

.form-control:focus {
    border-color: var(--color-tertiary);
    ;
    box-shadow: none;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-primary);
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-primary);
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-primary);
}

.footer-nav-links {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 30px;
}

.footer-nav-links a {
    text-decoration: none;
    color: var(--color-tertiary);
    font-size: var(--para-p1);
    font-weight: 500;
    margin-right:16px;
}

.footer-download-app-links {
    text-align: left;
}

.download-links {
    display: flex;
    align-items: center;
}

.app-download-links {
    margin-right: 18px;
    ;
    font-size: 20px;
}

.contact-section-heading {
    font-size: 20px;
    font-weight: 800;

}

.contact-section {
    text-align: left;
}

.contact-text {
    font-size: var(--para-p1);
    font-weight: 400;
}

.footer-copyright {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 68px;
    margin-bottom:0
}

.desktop-view {
    display: block;
}

.social-media-links {
    margin-right: 18px;
}

.right-arrow-icon {
    color: var(--color-tertiary);
}

.footer-go-to-links p {
    line-height: 24px;
    margin-bottom: 0;
}

.footer-section-download-image img{
        width:109px;
}
.footer-section-social-media-links{
    margin-top:70px;
}

.footer-section-social-media-links img{
    margin-right:10px;
}

.footer-section-app-download{
    display:flex;
    align-items: center;
    margin-top:16px;
}


@media screen and (max-width:576px) {
    .footer {
        padding: 2rem var(--padding-p6);
    }

  
    .get-updates-field {
        width: 100%;
        margin-bottom: 30px;
    }

    .desktop-view {
        display: none !important;
    }

    .footer-nav-links {
        font-size: 16px;
       
    }


    .footer-copyright {
        margin-top: 3rem;
        margin-bottom: 0;
    }

    .contact-section-heading {
        margin-bottom: 2rem;

    }

    .footer-section-social-media-links{
        margin-top:30px;
    }
    .footer-heading{
        font-size:18px;
        margin-bottom:12px;
    }
}

@media (min-width: 577px) and (max-width: 1200px) {
    .footer {
        padding: var(--padding-p4) var(--padding-p5);
    }
    .footer-heading {
        font-size:22px;
    }
    .contact-text{
        font-size:16px;
    }
}

@media (min-width: 577px) and (max-width: 854px) {
    .app-download-links {
        margin-right: 6px;
    }
}