.waitlist-header{
    margin-bottom: 3rem;
}
.waitlist-header-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.waitlist-social-media-links p{
    margin-bottom: 0;
    margin-right: 0.75rem;
    color:var(--color-tertiary);
    font-size: 20px;
    font-weight: 500;
}

@media screen and (max-width:576px){
 
    .waitlist-brand-logo img{
        width:35px;
    }
    .waitlist-header{
        margin-bottom:2rem;
    }
   
}

