.story-content {
    text-align: center;
    padding: 4rem 12rem;
    color: var(--color-tertiary);
    line-height:32px;
}

.team {
    background-color: #048B9E;
    padding: var(--padding-p2) var(--padding-p1);
}

.founder-message-video {
    width: 78%;
}

.team-content {
    margin-bottom: 24px;
}



.founder-message-title {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 24px;
}

.founder-message-text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 56px;
    line-height:28px;
    padding: 0 16rem;
}

.founder-message {
    padding: 4rem 12rem;
    color: var(--color-tertiary);
    line-height:32px;
}

.team-section {
    padding-top: 4rem;
    position: relative;
}

.team-section-heading {
    color: var(--color-tertiary);
    font-size: var(--heading-h1);
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.team-cards {
    background-color: #048B9E;
    text-align: center;

}

.team-cards img {
    margin-right: auto;
    margin-left: auto;
    width:70%
}

.team-card-title {
    padding: 12px 0px 0px 0px;
}

.team-card-heading-1 {
    color: var(--color-tertiary);
    font-size: var(--heading-h4);
    font-weight: 600;
}

.team-card-heading-2 {
    color: var(--color-tertiary);
    font-size: var(--para-p1);
    font-weight: 600;
}

.team-section-2 {
    margin-top: 6rem;
}

.sticky-box {
    background-color: #315C66;
    padding: 50px;
    /* position: absolute;
    top: 107%;
    left: 10%; */
    /* width: 80% */
}

.sticky-box-heading {
    color: var(--color-tertiary);
    font-size: 42px;
    font-weight: 700;
    line-height:36px;
    margin-bottom:24px;

}

.sticky-box-text {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-tertiary);
    line-height:28px;
    margin-bottom:0
}

.team-section-1 {
    margin: 4.5rem auto 0 auto;
    width:80%;

}

.story-text {
    font-size: 16px;
    font-weight: 400;
    padding:0 16rem;
    line-height:28px;
    margin-bottom:0
}

.team-section-subheading{
    font-size: var(--heading-h2);
    color:var(--color-tertiary);
    font-weight:600;
    padding: 0 9%;
    margin-bottom:0;
}

@media screen and (max-width:576px) {
    .team{
        padding: 2rem var(--padding-p6);
    }

    .story-text {
        font-size: 16px;
        padding:0
    }

    .story-content {
        padding: 2rem 1rem 2rem 1rem;
    }

    .founder-message-title {
        font-size: 24px;
        margin-bottom:22px
    }

    .founder-message-text {
        font-size: 16px;
        margin-bottom:22px;
        padding:0
    }

    .team-section {
        padding: 2rem 0 2rem 0;
    }

    .team-section-heading {
        font-size: var(--heading-h3);
    }

    .team-section-1 {
        margin:0;
        width:100%
    }

    .founder-message {
        padding: 2rem 1rem 2rem 1rem;
    }
    .team-content{
        padding:0 16px;
        width:50%
    }
    .founder-message-video{
        width:100%
    }
    .team-cards img{
        width:60%
    }
    .sticky-box{
        padding:32px;
    }
    .team-section-subheading{
        font-size: var(--para-p1);
        margin-bottom: 40px;
    }
}

@media (min-width: 577px) and (max-width: 1200px) {
    .founder-message {
        padding: 3rem 5rem;
    }

    .story-content {
        padding: 3rem 5rem;
    }
    .story-text{
        padding:0 ;
    }
   
    .sticky-box {
        background-color: #315C66;
        padding: 50px;
        /* position: absolute;
        top: 100%;
        left: 10%;
        width: 80% */
    }
    .team-section{
        padding-top:2rem;
    }
    .founder-message-title{
        font-size:32px;
        margin-bottom:18px;
    }
    .founder-message-text{
        padding:0;
        margin-bottom:48px;
    }
    .team-section-heading{
        font-size:var(--heading-h2);
    }
    .team{
        padding: var(--padding-p4) var(--padding-p5);
    }
    .team-section-subheading{
        font-size: var(--heading-h3);
    }
}