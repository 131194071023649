.app-features{
    text-align: left;
    padding: 4rem 24rem 0 24rem;
}

.feature-content{
    margin-bottom:3rem; 
}

.app-features-text{
    margin-bottom: 3.6rem;
    color: var(--color-primary);
    font-size: var(--heading-h2);
    font-weight: 700;
    list-style: 64px;
}

.feature-card{
    border:none;
    border-radius: 4px;
    background:var(--color-primary);
    color:var(--color-tertiary);
    padding:48px 24px; 
}
.feature-card-title{
    font-weight: 700;
    font-size: var(--heading-h3);
}
.feature-card-content{
    font-size: var(--para-p1);
    font-weight: 400;
    line-height:24px;
}
.app-feature-content{
    margin-bottom: 7rem;

}

.feature-content-card{
    padding-left:0
}

.feature-card img{
    border-radius: 0;
}

.app-feature-hero-image{
    width:100%
}

.apply-mobile-image-div2{
    background-color: var(--color-secondary);
    padding:0 var(--padding-p1) var(--padding-p2) var(--padding-p1);
    
}

.apply-mobile-image-div2-img{
    margin-top:-226px;
}

.apply-mobile-image-div1{
    font-size: 42px;
    font-weight:700;
}

.apply-app-download{
    margin-top:4%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-app-download img{
    object-fit: cover;
    height:54px;
}
.features-left-aligned{
    padding-right:1.5rem;
}

.features-right-aligned{
    padding-left:1.5rem;
}

.apply-section-heading{
    font-size: var(--heading-h1);
    font-weight:700
}

.apply-mobile-subheading{
    font-size: var(--heading-h2);
    color: var(--color-tertiary);
}

.apply-mobile-para{
    font-size:20px;
    color: var(--color-tertiary);
}

.apply-mobile-subheading1{
   width:80%;
   margin:0 auto;
}

@media screen and (max-width:576px){
    .app-features{
        padding: var(--padding-p5) var(--padding-p6) 0 var(--padding-p6);
        margin-top:0
    }

    .app-features-text{
        font-size:var(--heading-h3);
        margin-bottom:1rem;
        padding-left:1rem;
        padding-right:1rem;
        line-height: 37px;;
    }
    .feature-content{
        width:100%
    }
    .app-feature-content{
        margin-bottom:2rem;
    }
 
    .features-left-aligned{
        padding-right:12px;
    }
    
    .features-right-aligned{
        padding-left:12px;
    }

    .feature-content{
        margin-bottom:24px;
    }
    .apply-section-heading{
        font-size: var(--heading-h3);
        font-weight:700
    }
    .apply-mobile-subheading1{
        padding:0;
    }
    .apply-mobile-subheading{
        font-size: var(--heading-h4);
    }
    .apply-mobile-image-div2{
        padding:0 var(--padding-p6) 2rem var(--padding-p6);
    }
    .apply-app-download img{
        object-fit: cover;
        height:40px;
    }

    .apply-mobile-para{
        font-size:var(--para-p1);
    }
}

@media (min-width: 577px) and (max-width: 1200px){
    .app-features{
        padding:5rem var(--padding-p5) 0 var(--padding-p5);
    }

    .feature-card{
        padding:32px 24px;
    }

    .feature-content-card{
        padding:8px 0 0 0;
    }
    .apply-section-heading{
        font-size: var(--heading-h2);
        font-weight:700
    }

    .apply-mobile-subheading{
    font-size: var(--heading-h3);
    }

    .apply-mobile-image-div2{
        padding:0 var(--padding-p5) var(--padding-p4) var(--padding-p5);
    }
}

@media screen and (max-width:576px){

    .app-features{
        padding:var(--padding-p5) var(--padding-p6) 0 var(--padding-p6);
    }

    .features-left-aligned{
        padding-right:0;
        padding-left:0;
    }
    
    .features-right-aligned{
        padding-right:0;
        padding-left:0;
    }
}