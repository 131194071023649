.kitchen-wrapper{
    padding:var(--padding-p2) var(--padding-p1) var(--padding-p2) var(--padding-p1);
    
}

.kitchen-tech-heading{
    color:var(--color-secondary);
    margin-bottom: 3.5rem;
    font-size:var(--heading-h1);
    font-weight:700;
    
}
.kitchen-tech-card-body{
    margin-top:2.12rem;
    margin-bottom:2.12rem;
    color:var(--color-secondary);
}
/* .kitchen-tech-image{
    width:65%;
} */
.kitchen-tech-card-body-heading{
    font-weight: 700;
    font-size: 20px;;
}
.kitchen-tech-card-body-text{
    font-size: var(--para-p1);
    font-weight: 300;
    color: var(--color-secondary);
}

.kitchen-tect-explore{
    padding: 0 15%;
}
@media screen and (max-width: 576px){
    .kitchen-wrapper{
        padding:2rem var(--padding-p6);
    }
    .kitchen-tech-heading{
        font-size: var(--heading-h3);
        margin-bottom:32px;
    }
    .kitchen-tech{
        padding:0 36px;
    }
    .kitchen-tech-card-body{
        margin-bottom:2.2rem;
        margin-top:2.2rem;
    }
    .kitchen-tect-explore{
        padding:0
    }
}

@media (min-width: 577px) and (max-width: 1200px){
    .kitchen-wrapper{
        padding:var(--padding-p4) var(--padding-p5);
    }

    .kitchen-tech-heading{
        font-size:var(--heading-h2);
        margin-bottom:3rem;
    }

    .kitchen-tech-card-body-text{
        font-weight:400
    }
    .kitchen-tect-explore{
        padding: 0 6rem;
    }
}